import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import '../styles/resume.scss';

export const Resume = () => (
  <article className="resume">
    <div className="resume-header">
      <button
        onClick={() => {
          window.print();
        }}
        title="Print resume">
        <FontAwesomeIcon aria-hidden="true" icon={faPrint} />
      </button>
    </div>
    <div className="experience">
      <h2>Experience</h2>
      <ul>
        <li>
          <div>
            <h5>2021 - present</h5>
            <h3>Netflix</h3>
            <h4>Senior Software Engineer</h4>
            <h5>Seattle, WA</h5>
          </div>
          <div>
            <p>
              Building highly scalable experiences to attract new users, with
              measurable and direct impact on global revenue and subscriber
              growth. Working across web, smart TVs, and mobile devices to
              address complex architectural problems and deliver tools which
              balance performance constraints with developer-friendly solutions
              that scale.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h5>2010 - present</h5>
            <h3>CodeSqwerl</h3>
            <h4>Freelance Software Engineer</h4>
            <h5>Seattle, WA</h5>
          </div>
          <div>
            <p>
              Responsible for the design, development, testing, delivery and
              maintenance of web sites, major and minor applications and
              components. In charge of creating web applications and components
              from the requirements stage, through design, to production
              deployment.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h5>2017 - 2021</h5>
            <h3>AWS</h3>
            <h4>Front-End Engineer</h4>
            <h5>Seattle, WA</h5>
          </div>
          <div>
            <p>
              Responsibilities included the design, development, test and
              deployment of multiple front end applications for AWS's core
              offerings. Also provided technical, architectural, and
              customer-focused guidance across engineering and business teams,
              as well as building user experiences that were intuitive, highly
              performant, and secure.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h5>2015 - 2017</h5>
            <h3>SmartBear Software</h3>
            <h4>Senior Software Engineer</h4>
            <h5>Fort Lauderdale, FL</h5>
          </div>
          <div>
            <p>
              Developed and maintained major server monitoring SaaS applications
              and components for internal and external users. Contributed to
              platform stability and performance, and developed an user
              interface involving compatibility and seamless integration with
              various back end technologies such as JavaScript, Python and Java.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h5>2014 - 2015</h5>
            <h3>Office Depot</h3>
            <h4>Senior UI Developer</h4>
            <h5>Boca Raton, FL</h5>
          </div>
          <div>
            <p>
              As a UI Developer at one of the e-commerce business worldwide, I
              leveraged my knowledge to help the team design and develop a
              robust e-commerce checkout solution and an efficient customer
              service portal that yielded a measurable increase in productivity
              for Customer Service Representatives and boost online conversions.
            </p>
          </div>
        </li>
        <li>
          <div>
            <h5>2013 - 2014</h5>
            <h3>Dealer.com</h3>
            <h4>Web Developer</h4>
            <h5>Fort Myers, FL</h5>
          </div>
          <div>
            <p>
              As a full stack developer for one of the largest automotive online
              marketplaces in the US, contributed to the development of a custom
              CMS / CRM platform for dealerships to increase traffic and leads.
              Created and maintained web components for internal and external
              clients.
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div className="education">
      <h2>Education</h2>
      <ul>
        <li>
          <h3>Full Sail University</h3>
          <h4>Web Development, 2014</h4>
        </li>
        <li>
          <h3>Case Western Reserve University</h3>
          <h4>Economics, 2012</h4>
        </li>
      </ul>
    </div>
    <div className="skills">
      <h2>Skills</h2>
      <ul>
        <li>Software design and architecture</li>
        <li>Responsive and mobile design</li>
        <li>Cross-browser development</li>
        <li>Accessibility</li>
        <li>Web performance</li>
        <li>Testing and debugging</li>
        <li>E-commerce and content management systems</li>
        <li>Task, build and process automation</li>
        <li>RESTful services and APIs</li>
        <li>Version control</li>
      </ul>
    </div>
    <div className="skills">
      <h2>Technologies</h2>
      <ul>
        <li>HTML, CSS</li>
        <li>JavaScript, TypeScript</li>
        <li>Node, Express</li>
        <li>jQuery</li>
        <li>Angular, RxJs</li>
        <li>React, Redux, Hooks</li>
        <li>Sass, LESS</li>
        <li>Bootstrap, Material, Foundation</li>
        <li>Webpack, Gulp, Bower</li>
        <li>Git</li>
        <li>MongoDB, Neo4j</li>
        <li>Chai, Jest, Jasmine, PhantomJS, Puppeteer</li>
        <li>Wordpress, Woocommerce</li>
      </ul>
    </div>
  </article>
);

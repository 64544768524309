import React from 'react';
import { Layout } from '../components/layout';
import { Resume } from '../components/resume';

const IndexPage = () => (
  <Layout>
    <Resume />
  </Layout>
);

export default IndexPage;
